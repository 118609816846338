export const menuItems = [
  
 


      { name: "setup-part-1/", label: `Audit + Restructure 🧱` },
      { name: "setup-part-2/", label: `Relaunch + Growth 🚀` },
        
  
     
  
      

  
];
