import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "../Core";
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 1.2;
    a {
      color: white !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => '#5454d4'} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid #413e65`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? '#ffffff' : '#19191b'};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? '#ffffff' : '#19191b'} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => '#5454d4'} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "rgb(39, 64, 105)" : "rgb(39, 64, 105)"}>
        <Container>
          {/* <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">

              <Col sm="12" className="mt-5 mt-lg-0">
                <Row>
                 
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Compare
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        <li>
                          <Link to={"/compare/accounting-software/"} style={{fontSize: 14}}>
                            Accounting software
                          </Link>
                        </li>
                        <li>
                        <Link to={"/compare/amazon-software/"} style={{fontSize: 14}}>
                            Amazon software
                          </Link>
                        </li>
                        <li>
                        <Link to={"/compare/crm-software/"} style={{fontSize: 14}}>
                            CRM software
                          </Link>
                        </li>
                        <li>
                        <Link to={"/compare/payment-providers/"} style={{fontSize: 14}}>
                            Payment providers
                          </Link>
                        </li>
                        <li>
                        <Link to={"/compare/seo-software/"} style={{fontSize: 14}}>
                            SEO software
                          </Link>
                        </li>
                        <li>
                        <Link to={"/compare/website-builders/"} style={{fontSize: 14}}>
                            Website builders
                          </Link>
                        </li>
                      
                        <li>
                        <Link to={"/compare/wordpress-hosting/"} style={{fontSize: 14}}>
                            Wordpress hosting
                          </Link>
                        </li>
                      
                      
                      </UlStyled>
                    </div>
                  </Col>

                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        How To Guides
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        
                      <li>
                        <Link to={"/choose-business-software/"} style={{fontSize: 14}}>
                            Choose business software
                          </Link>
                        </li>
                        <li>
                        <Link to={"/find-an-investor/"} style={{fontSize: 14}}>
                            Find an investor
                          </Link>
                        </li>
                        <li>
                        <Link to={"/find-an-office/"} style={{fontSize: 14}}>
                            Find an office
                          </Link>
                        </li>
                        <li>
                          <Link to={"/build-a-website/"} style={{fontSize: 14}}>
                            Build a website
                          </Link>
                        </li>
                        <li>
                          <Link to={"/get-more-clients/"} style={{fontSize: 14}}>
                            Get more clients
                          </Link>
                        </li>
                        <li>
                          <Link to={"/how-to-recruit/"} style={{fontSize: 14}}>
                            How to recruit
                          </Link>
                        </li>
                        <li>
                          <Link to={"/business-tactics/"} style={{fontSize: 14}}>
                            Business tactics
                          </Link>
                        </li>

                      
                      </UlStyled>
                    </div>
                  </Col>


                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Business Ideas
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        <li>
                          <Link to={"/dark-kitchen/"} style={{fontSize: 14}}>
                            Start a dark kitchen
                          </Link>
                        </li>
                        <li>
                        <Link to={"/start-a-shipping-business/"} style={{fontSize: 14}}>
                            Shipping business
                          </Link>
                        </li>
                        <li>
                        <Link to={"/paid-newsletter-business/"} style={{fontSize: 14}}>
                            Paid newsletters
                          </Link>
                        </li>
                        <li>
                        <Link to={"/business-ideas/"} style={{fontSize: 14}}>
                            2021 UK business ideas
                          </Link>
                        </li>
                      
                      </UlStyled>
                    </div>
                  </Col>


                 
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        About Us
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        <li>
                          <Link to={"/contact/"} style={{fontSize: 14}}>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                        <Link to={"/privacy-policy/"} style={{fontSize: 14}}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                           <Link to={"/terms-conditions/"} style={{fontSize: 14}}>
                            Terms & Conditions
                          </Link>
                        </li>
                        <li>
                           <Link to={"/sitemap/"} style={{fontSize: 14}}>
                            Sitemap
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
               
               
                
                </Row>
              </Col>
            </Row>
          </Box> */}
          {/* <CookieConsent  style={{ background: "#19191a" }}>This website uses cookies to enhance the user experience.</CookieConsent> */}

<div></div>


          <CookieConsent
  buttonText="Continue"
  cookieName="myAwesomeCookieName2"
  style={{ height: 63, marginBottom: 0, background: "white", fontSize: 14, fontWeight: 300, color: '#767676', fontFamily:'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'  }}
  buttonStyle={{ paddingTop: 0, paddingBottom: 0, height: 40, background: "white", fontSize: "13px", color: '#323232', border: '1px solid #767676', borderRadius: 4, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
>
  <div style={{marginTop: -10}}>We use cookies. By browsing our site you agree to our use of cookies.</div>

</CookieConsent>

          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center" id='footerMessage'>
         
              
              {/* <Col sm="10"> */}
              {/* <Row> */}
          <p style={{lineHeight: 1.3, color: 'white', fontSize: 12, textAlign: 'center', maxWidth: 600, margin: 'auto'}}>2022 &copy; Kohdao Ltd. 7 Clapham Park Terrace, London, UK. Registration 06261702. Data Protection No: ZA891746.</p>
          {/* </Row> */}
   
              {/* </Col> */}
              {/* <Col sm="2" className="text-sm-right text-center" id='footerSocial'> */}
                {/* <ul className="social-icons">
                  <li>
                    <a href="https://twitter.com/startupmagUK" rel="noreferrer" target="_blank">
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/startupmaguk/" rel="noreferrer" target="_blank">
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/startupmagUK/" rel="noreferrer" target="_blank">
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                </ul> */}
              {/* </Col> */}
            </Row>
          </CopyRightArea>
       
        </Container>
      </Box>
    </>
  );
};

export default Footer;
